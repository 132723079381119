@import '../../../node_modules/bootstrap/scss/_functions.scss';
@import '../../../node_modules/bootstrap/scss/_variables.scss';
@import '../../../node_modules/bootstrap/scss/_mixins.scss';

@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    background: #F9F9F9;
  }
  .modal-footer {
    background: #ffffff
  }
  .modal-body {
    overflow-y: auto;
  }

}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}


.components-container, .settings, .design {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.components-container {
  .components {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
    font-size: 0.9rem;
    padding-right: 10px;

    .list-group-item {
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }

    }
  }

  .component-image {
    img {
      transition: all 0.3s;
    }
  }

  .component-image:hover {
    img {
      margin-left: 10px;
    }
  }


  .list-group-item {
    cursor: pointer;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 0px;
    z-index: 1;
    background: #F9F9F9;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

  }
}

.settings {
  max-height: 90vh;
  overflow-y: scroll;
}

.design {
  max-height: 90vh;
  width: 100%;
  overflow-y: scroll;
  transition: all 0.3s;

  .design-element {
    position: relative;
    padding-block: 10px;
    border: 2px solid transparent;
    transition: all 0.3s;
    margin-bottom: 0.5rem;
    min-height: 100px;

    &.active {
      border-color: #5975ff;
    }

    img {
      max-width: 80%;
      display: block;
      margin: 0 auto;
    }

    .actions {
      display: none;

      span {
        cursor: pointer;
      }

      position: absolute;
      right: 1rem;
    }

    &:hover {
      border-color: #5975ff;

      .actions {
        display: flex;
        gap: 15px;
        flex-direction: column
      }
    }
  }

  img {
    cursor: pointer;
    margin-bottom: 1rem;
  }
}


.footer-disabled {
  transition: all 0.3s linear;
  width: 0;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: #00000040;

  &.enabled {
    width: 100%;
  }
}

.gallery-image {
  border: 2px solid transparent!important;
  cursor: pointer;
}

.gallery-image:hover {
  transition: all 300ms;
  border: 2px solid #333333!important;
}

.selected-image {
  border: 2px solid #333333 !important;
}

.loader {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}
