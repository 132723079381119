.time {
    font-size: 9px !important
}

.socials i {
    margin-right: 14px;
    font-size: 17px;
    color: #d2c8c8;
    cursor: pointer
}

.feed-image img {
    max-height: 500px;
    margin: 0 auto;
    display: block;
}
.image-slider{
    max-height: 500px;
    margin: 0 auto;
    display: block;
}
