.transaction-details {
  text-align: center;
  .table{
    margin-top: 1rem;
  }
  tbody{
    display: inline-table;
  }
  tr {
    td:first-child {
      font-weight: bold;
    }
  }
}
