/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 2.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/cropperjs";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";


/*profile*/
.user-pic-name {
  text-transform: uppercase;
  background: #0f29e3d4;
  padding: 8px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.highlight {
  border: 1px solid green;
}
