.ui-state-highlight {
    display: flex;
    height: 500px;
    width: 400px;
    line-height: 1.2em;
    background: green
}

.pointer {
    cursor: pointer;
}
